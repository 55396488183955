import React, {useState} from 'react'

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, phone, message } = formData;
    const mailtoLink = `mailto:info@spaceport-rostock.de?subject=Contact from ${name}&body=${encodeURIComponent(message)}%0D%0A%0D%0AName: ${encodeURIComponent(name)}%0D%0AEmail: ${encodeURIComponent(email)}%0D%0APhone: ${encodeURIComponent(phone)}`;

    // Use setTimeout to delay the redirection
    setTimeout(() => {
        window.location.href = mailtoLink;
        setFormData({ name: '', email: '', phone: '', message: '' }); // Clear the form data
    }, 100); // Delay of 100 milliseconds
  };

  return (
    <div className='contact-form-container'>
      <form onSubmit={handleSubmit} className='flex flex-col items-center justify-center w-full'>
        <div className='w-full max-w-2xl mb-4'>
          <label htmlFor="name" className='block text-lg mb-2'>Name <span className='text-[#5B9BD5]'>(Required)</span>:</label>
          <input
            type="text"
            id="name"
            name="name"
            className='block w-full p-2 rounded-md text-black bg-gray-300 outline-none focus:ring-2 ring-[#5B9BD5]'
            required
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div className='w-full max-w-2xl mb-4'>
          <label htmlFor="email" className='block text-lg mb-2'>Email <span className='text-[#5B9BD5]'>(Required)</span>:</label>
          <input
            type="email"
            id="email"
            name="email"
            className='block w-full p-2 rounded-md text-black bg-gray-300 outline-none focus:ring-2 ring-[#5B9BD5]'
            required
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className='w-full max-w-2xl mb-4'>
          <label htmlFor="phone" className='block text-lg mb-2'>Phone:</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            className='block w-full p-2 rounded-md text-black bg-gray-300 outline-none focus:ring-2 ring-[#5B9BD5]'
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div className='w-full max-w-2xl mb-4'>
          <label htmlFor="message" className='block text-lg mb-2'>Message <span className='text-[#5B9BD5]'>(Required)</span>:</label>
          <textarea
            id="message"
            name="message"
            className='block w-full p-2 rounded-md text-black bg-gray-300 outline-none focus:ring-2 ring-[#5B9BD5]'
            required
            value={formData.message}
            onChange={handleChange}
            rows={5}
          />
        </div>
        <button type="submit" className='mt-4 bg-[#5B9BD5]/70 p-2 rounded-md px-6 hover:bg-[#5B9BD5] form-submit'>Send Message</button>
      </form>
    </div>
  )
}

export default ContactForm;