import surf1 from "../data/surface_1.webp";
import surf2 from "../data/surface_2.webp";
import surf3 from "../data/surface_3.webp";

const Carousel_Info = () => {
  
  return (
    <div className="flex flex-col items-center justify-center p-5">
      <div className="mb-6">
        <div className="laptop:text-lg text-center p-6 max-w-5xl mx-auto bg-[#5B9BD5]/80 rounded-md shadow-lg text-white">
          <h3 className="font-bold pb-2 text-xl">Moon Expedition Simulation Services</h3>
          <ul className="list-disc pl-5 text-justify">
            <li>Internal Driving Area: Our expansive 17m x 5m x 0.4m internal landscape features 40 tons of RS1 mare regolith simulant, providing a lifelike lunar terrain for a variety of testing and research activities.</li>
            <li>Optional Terrain Modeling: Customize the surface with optional features such as craters, rocks, and other geological formations to mirror specific lunar sites or to meet experimental requirements.</li>
            <li>Extended Outdoor Driving Options: For a more extensive testing environment, opt for our outdoor driving area equipped for long-distance explorations, featuring diverse terrain such as hills and uneven surfaces.</li>
            <li>Rover Platform Customization: Specifically designed for payload testing, our adjustable rover platform can accommodate payloads up to 70cm by 70cm and weighing up to 10kg, ideal for equipment testing and deployment simulations.</li>
          </ul>
          <img
            src={surf1}
            alt="Moon Expedition Simulation"
            className="mt-6 w-full max-w-4xl mx-auto rounded-md shadow-lg object-cover"
          />
        </div>
        
      </div>
      <div className="my-6">
        <div className="laptop:text-lg text-center p-6 max-w-5xl mx-auto bg-[#5B9BD5]/80 rounded-md shadow-lg text-white">
          <h3 className="font-bold pb-2 text-xl">Advanced Lunar and Planetary Simulation Facilities</h3>
          <ul className="list-disc pl-5 text-justify">
            <li>Mobile Gantry with Gravity Assist: Our robust mobile gantry system is designed to handle rovers or payloads weighing up to 500kg. This feature is perfect for simulating vehicle and equipment movement under different gravitational conditions.</li>
            <li>Gravity Compensation Systems: Customize your simulation with weight compensation settings for various celestial bodies including the Moon, Mars, and asteroids, to accurately replicate their unique gravity scenarios.</li>
            <li>Comprehensive Onsite Amenities: Take advantage of our well-equipped facility that includes free WLAN access, and a dedicated workbench with electrical and mechanical tools to support all phases of your project.</li>
            <li>Additive Manufacturing and Fabrication Tools: Opt for additional tools with our optional 3D printer and laser cutter, ideal for rapid prototyping and custom part creation directly at the testing site.</li>
          </ul>
          <img
            src={surf2}
            alt="Advanced Lunar and Planetary Simulation"
            className="mt-6 w-full max-w-4xl mx-auto rounded-md shadow-lg object-cover"
          />
        </div>
      </div>
      <div className="my-6">
        <div className="laptop:text-lg text-center p-6 max-w-5xl mx-auto bg-[#5B9BD5]/80 rounded-md shadow-lg text-white">
          <h3 className="font-bold pb-2">Lunar Lighting Simulation Services</h3>
          <ul className="list-disc pl-5 text-justify">
            <li>High-Power Lighting Systems: Equipped with 2000W professional lighting systems featuring Fresnel lenses, our facility offers superior illumination capabilities essential for accurate visual simulations.</li>
            <li>Adaptive Lunar Lighting Conditions: Tailor your experiment by simulating an array of lunar lighting environments, from the stark sunlight at the lunar equator to the more subdued lighting conditions at the poles.</li>
            <li>"Smart Hands" Remote Operation Service: Send us only your equipment and we'll do the rest. Our "Smart Hands" service allows you to train your lunar or planetary simulation remotely. We handle the setup and operation of your equipment under live camera supervision, ensuring precise and real-time execution of your specified tasks.</li>
          </ul>
          <img
            src={surf3}
            alt="Lunar Lighting Simulation"
            className="mt-6 w-full max-w-4xl mx-auto rounded-md shadow-lg object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default Carousel_Info;