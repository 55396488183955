import React from "react";
import { Link } from "react-router-dom";

const Privacy = () => {
  return (
    <div className="max-h-full w-full text-white flex flex-col justify-start items-center">
      <div className="bg-black/75 my-6 rounded-md text-left p-4 w-[80vw] xl:w-[70vw] 2xl:w-[60vw]">
        <h1 className="text-center text-[#5B9BD5] text-4xl font-bold">
          Privacy
        </h1>
        <h2 className="text-center text-[#5B9BD5] text-lg font-medium mt-2">
          DATA PROCESSING GUIDELINES
        </h2>

        <div className="mt-5 text-sm tablet:text-md text-wrap break-words overflow-hidden">
          <ol className="list-decimal list-inside">
            <li className="my-2 text-[#5B9BD5]">
              {/*1*/}
              <span className="font-medium">
                Name und Kontaktdaten des für die Verarbeitung Verantwortlichen
                sowie des betrieblichen Datenschutzbeauftragten
              </span>
              <p className="m-2 text-white">
                Diese Datenschutzhinweise gelten für die Datenverarbeitung
                durch: <br />
                Planetary Transportation Systems GmbH <br />
                Albert-Einstein-Ring 22, 14532 Kleinmachnow <br />
                E-Mail: info@pts.space <br />
                Telefon: +49 (0)30 54 90 65 280 <br />
                Fax: +49 (0)30 92036483 <br />
                Internet: pts.space <br />
                – nachfolgend „Firma“ genannt – <br />
                Datenschutzbeauftragter <br />
                Der betriebliche Datenschutzbeauftragte der Firma ist unter der
                o.g. Anschrift, zu Hd. Herrn Robert Boehme, beziehungsweise
                unter folgenden Kontaktdaten erreichbar: <br />
                <br />
                Herr Robert Boehme <br />
                E-Mail: privacy@pts.space
              </p>
            </li>
            <li className="my-2 text-[#5B9BD5]">
              {/*2*/}
              <span className="font-medium">
                Erhebung und Speicherung personenbezogener Daten sowie die Art
                und der Zweck deren Verwendung
              </span>
              <ol className="m-2 text-white">
                <li>
                  <strong>a) Beim Besuch der Website unserer Firma</strong>
                  <p>
                    Beim Aufrufen unserer Website www.pts.space werden durch den
                    auf Ihrem Endgerät zum Einsatz kommenden Browser automatisch
                    Informationen an den Server unserer Website gesendet. Diese
                    Informationen werden temporär in einem sog. Logfile
                    gespeichert. Folgende Informationen werden dabei ohne Ihr
                    Zutun erfasst und bis zur automatisierten Löschung
                    gespeichert:
                  </p>
                  <ul className="list-disc list-inside m-2 ml-6 text-white">
                    <li>IP-Adresse des anfragenden Rechners</li>
                    <li>Datum und Uhrzeit des Zugriffs</li>
                    <li>Name und URL der abgerufenen Datei</li>
                    <li>
                      Website, von der aus der Zugriff erfolgt (sog.
                      Referrer-URL)
                    </li>
                    <li>
                      Verwendeter Browser und ggf. das Betriebssystem Ihres
                      Rechners sowie
                    </li>
                    <li>Name Ihres Access-Providers.</li>
                  </ul>
                  <p>
                    Die vorgenannten Daten werden durch unsere Firma zu
                    folgenden Zwecken verarbeitet:
                  </p>
                  <ul className="list-disc list-inside m-2 ml-6 text-white">
                    <li>
                      Gewährleistung eines reibungslosen Verbindungsaufbaus der
                      Website
                    </li>
                    <li>
                      Gewährleistung einer komfortablen Nutzung unserer Website
                    </li>
                    <li>
                      Auswertung der Systemsicherheit und -stabilität sowie
                    </li>
                    <li>Zu weiteren administrativen Zwecken</li>
                  </ul>
                  <p>
                    Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6
                    Abs. 1 Satz 1 lit. f) DSGVO. Das berechtigte Interesse
                    unserer Firma folgt aus oben aufgelisteten Zwecken zur
                    Datenerhebung. In keinem Fall verwenden wir die erhobenen
                    Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.
                    Darüber hinaus setzen wir beim Besuch unserer Website
                    Cookies sowie Analysedienste ein. Nähere Erläuterungen dazu
                    erhalten Sie unter der nachstehenden Ziff. 4 dieser
                    Datenschutzhinweise.
                  </p>
                </li>
                <li className="my-4">
                  <strong>
                    b) Bei Nutzung der auf unserer Website angegebenen
                    E-Mail-Adressen
                  </strong>
                  <p>
                    Bei Fragen jeglicher Art bietet Ihnen unsere Firma die
                    Möglichkeit, mit uns über eine der auf der Website
                    angegebenen E-Mail-Adressen Kontakt aufzunehmen. Dabei darf
                    von Ihrer Seite Ihre Absenderkennung nicht unterdrückt
                    werden, damit wir wissen, von wem die Anfrage stammt und um
                    diese beantworten zu können. Weitere, für unsere Firma
                    bestimmte Angaben können Sie freiwillig machen. Die
                    Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns
                    erfolgt nach Art. 6 Abs. 1 Satz 1 lit. a) DSGVO auf
                    Grundlage Ihrer freiwillig erteilten Einwilligung.
                  </p>
                  <br />
                  <p>
                    Die von uns mit Erhalt Ihrer E-Mail erhobenen
                    personenbezogenen Daten werden nach Erledigung der von Ihnen
                    gestellten Anfrage manuell gelöscht, es sei denn, dass Sie
                    in die Speicherung Ihrer vorbezeichneten Daten durch unsere
                    Firma über den vorgenannten Zeitpunkt hinaus durch eine
                    gesonderte Erklärung einwilligen.
                  </p>
                </li>
              </ol>
            </li>
            <li className="my-2 text-[#5B9BD5]">
              {/*3*/}
              <span className="font-medium"> Weitergabe von Daten </span>
              <p className="m-2 text-white">
                Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen
                als den im Folgenden aufgeführten Zwecken findet nicht statt.
                Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:
              </p>
              <ul className="list-disc list-inside m-2 ml-6 text-white">
                <li>
                  Sie nach Art. 6 Abs. 1 Satz 1 lit. a) DSGVO Ihre ausdrückliche
                  Einwilligung dazu erteilt haben;
                </li>
                <li>
                  die Weitergabe nach Art. 6 Abs. 1 Satz 1 lit. f) DSGVO zur
                  Geltendmachung, Ausübung oder Verteidigung von
                  Rechtsansprüchen erforderlich ist und kein Grund zur Annahme
                  besteht, dass Sie ein überwiegendes schutzwürdiges Interesse
                  an der Nichtweitergabe Ihrer Daten haben;
                </li>
                <li>
                  für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 Satz
                  1 lit. c) DSGVO eine gesetzliche Verpflichtung besteht; oder:
                </li>
                <li>
                  dies gesetzlich zulässig und nach Art. 6 Abs. 1 Satz 1 lit. b)
                  DSGVO für die Abwicklung von Vertragsverhältnissen mit Ihnen
                  erforderlich ist.
                </li>
              </ul>
            </li>
            <li className="my-2 text-[#5B9BD5]">
              {/*4*/}
              <span className="font-medium">
                Cookies, Analyse-Tools, weitere Dienste, Newsletter
                <ol className="m-2 text-white">
                  <li>
                    <strong>a) Allgemeine Hinweise zu Cookies</strong>
                  </li>
                  <li className="my-4">
                    <strong>b) Analyse-Tool: Google Analytics</strong>
                    <p>
                      Zum Zwecke der bedarfsgerechten Gestaltung und
                      fortlaufenden Optimierung der Website unserer Firma nutzen
                      wir das Analyse-Tool „Google Analytics“. Hierbei handelt
                      es sich um einen Webanalysedienst der Google Inc. ({" "}
                      <Link
                        to="https://www.google.de/intl/de/about/"
                        className="text-[#5B9BD5] text"
                      >
                        https://www.google.de/intl/de/about/
                      </Link>
                      ), 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA
                      (nachfolgend kurz „Google“ genannt).
                    </p>{" "}
                    <br />
                    <p>
                      In diesem Zusammenhang werden pseudonymisierte
                      Nutzungsprofile erstellt und Cookies (siehe unter
                      vorstehendem lit. a) dieser Datenschutzhinweise)
                      verwendet. Ein Rückschluss auf Ihre Identität ist dadurch
                      nicht möglich. Die durch den Cookie erzeugten
                      Informationen über Ihre Benutzung unserer Website wie
                    </p>{" "}
                    <ul className="list-disc list-inside m-2 ml-6 text-white">
                      <li>Browser-Typ/-Version,</li>
                      <li>verwendetes Betriebssystem,</li>
                      <li>Referrer-URL (die zuvor besuchte Seite),</li>
                      <li>Hostname des zugreifenden Rechners (IP-Adresse),</li>
                      <li>Uhrzeit der Serveranfrage,</li>
                    </ul>
                    <p>
                      werden an einen Server von Google in den USA übertragen
                      und dort gespeichert. Die Informationen werden verwendet,
                      um die Nutzung der Website auszuwerten, um Reports über
                      die Websiteaktivitäten zusammenzustellen und um weitere
                      mit der Websitenutzung und der Internetnutzung verbundene
                      Dienstleistungen zu Zwecken der Marktforschung und
                      bedarfsgerechten Gestaltung dieser Internetseiten zu
                      erbringen. Auch werden diese Informationen gegebenenfalls
                      an Dritte übertragen, sofern dies gesetzlich
                      vorgeschrieben ist oder soweit Dritte diese Daten im
                      Auftrag verarbeiten. Es wird in keinem Fall Ihre
                      IP-Adresse mit anderen Daten von Google zusammengeführt.
                      Die IP-Adressen werden anonymisiert, so dass eine
                      Zuordnung nicht möglich ist (IP-Masking).
                    </p>{" "}
                    <br />
                    <p>
                      Sie können die Installation der Cookies durch eine
                      entsprechende Einstellung der Browser-Software verhindern;
                      wir weisen jedoch darauf hin, dass in diesem Fall
                      gegebenenfalls nicht sämtliche Funktionen dieser Website
                      vollumfänglich genutzt werden können.
                    </p>{" "}
                    <br />
                    <p>
                      Sie können darüber hinaus die Erfassung der durch das
                      Cookie erzeugten und auf Ihre Nutzung unserer Website
                      bezogenen Daten (inkl. Ihrer IP-Adresse) sowie die
                      Verarbeitung dieser Daten durch Google verhindern, indem
                      Sie ein Browser-Add-on herunterladen und installieren (
                      <Link
                        to="https://tools.google.com/dlpage/gaoptout?hl=de"
                        className="text-[#5B9BD5]"
                      >
                        https://tools.google.com/dlpage/gaoptout?hl=de
                      </Link>
                      ). Alternativ zum Browser-Add-on, insbesondere bei
                      Browsern auf mobilen Endgeräten, können Sie die Erfassung
                      durch Google Analytics zudem verhindern, indem Sie auf
                      diesen Link klicken. Es wird ein Opt-out-Cookie gesetzt,
                      das die zukünftige Erfassung Ihrer Daten beim Besuch der
                      Website unserer Firma verhindert. Der Opt-out-Cookie gilt
                      nur in diesem Browser und nur für unsere Website und wird
                      auf Ihrem Gerät abgelegt. Löschen Sie die Cookies in
                      diesem Browser, müssen Sie das Opt-out-Cookie erneut
                      setzen.
                    </p>{" "}
                    <br />
                    <p>
                      Weitere Informationen zum Datenschutz im Zusammenhang mit
                      Google Analytics finden Sie etwa in der Google
                      Analytics-Hilfe (
                      <Link
                        to="https://support.google.com/analytics/answer/6004245?hl=de"
                        className="text-[#5B9BD5]"
                      >
                        https://support.google.com/analytics/answer/6004245?hl=de
                      </Link>
                      ).
                    </p>
                  </li>
                  <li className="my-4">
                    <strong>
                      c) Verwendung von Scriptbibliotheken (Google Webfonts)
                    </strong>
                    <p>
                      Um unsere Inhalte browserübergreifend korrekt und grafisch
                      ansprechend darzustellen, verwenden wir auf dieser Website
                      Scriptbibliotheken und Schriftbibliotheken wie z. B.
                      Google Webfonts (
                      <Link
                        to="https://www.google.com/webfonts/"
                        className="text-[#5B9BD5]"
                      >
                        https://www.google.com/webfonts/
                      </Link>
                      ). Google Webfonts werden zur Vermeidung mehrfachen Ladens
                      in den Cache Ihres Browsers übertragen. Falls der Browser
                      die Google Webfonts nicht unterstützt oder den Zugriff
                      unterbindet, werden Inhalte in einer Standardschrift
                      angezeigt.
                    </p>{" "}
                    <br />
                    <p>
                      Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken
                      löst automatisch eine Verbindung zum Betreiber der
                      Bibliothek aus. Dabei ist es theoretisch möglich – aktuell
                      allerdings auch unklar ob und ggf. zu welchen Zwecken –
                      dass Betreiber entsprechender Bibliotheken Daten erheben.
                      Die Datenschutzrichtlinie des Bibliothekbetreibers Google
                      finden Sie hier:{" "}
                      <Link
                        to="https://www.google.com/policies/privacy/"
                        className="text-[#5B9BD5]"
                      >
                        https://www.google.com/policies/privacy/
                      </Link>{" "}
                    </p>
                  </li>
                  <li className="my-4">
                    <strong>d) Verwendung von Google Maps</strong>
                    <p>
                      Diese Webseite verwendet Google Maps API, um geographische
                      Informationen visuell darzustellen. Bei der Nutzung von
                      Google Maps werden von Google auch Daten über die Nutzung
                      der Kartenfunktionen durch Besucher erhoben, verarbeitet
                      und genutzt. Nähere Informationen über die
                      Datenverarbeitung durch Google können Sie den
                      Google-Datenschutzhinweisen entnehmen. Dort können Sie im
                      Datenschutzcenter auch Ihre persönlichen
                      Datenschutz-Einstellungen verändern. Ausführliche
                      Anleitungen zur Verwaltung der eigenen Daten im
                      Zusammenhang mit Google-Produkten finden Sie{" "}
                      <Link
                        to="https://support.google.com/accounts/answer/3024190"
                        className="text-[#5B9BD5]"
                      >
                        hier
                      </Link>
                    </p>
                  </li>
                  <li className="my-4">
                    <strong>e) Eingebettete YouTube-Videos</strong>
                    <p>
                      Auf einigen unserer Webseiten betten wir Youtube-Videos
                      ein. Betreiber der entsprechenden Plugins ist die YouTube,
                      LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. Wenn Sie
                      eine Seite mit dem YouTube-Plugin besuchen, wird eine
                      Verbindung zu Servern von Youtube hergestellt. Dabei wird
                      Youtube mitgeteilt, welche Seiten Sie besuchen. Wenn Sie
                      in Ihrem Youtube-Account eingeloggt sind, kann Youtube Ihr
                      Surfverhalten Ihnen persönlich zuzuordnen. Dies verhindern
                      Sie, indem Sie sich vorher aus Ihrem Youtube-Account
                      ausloggen.
                    </p>{" "}
                    <br />
                    <p>
                      Wird ein Youtube-Video gestartet, setzt der Anbieter
                      Cookies ein, die Hinweise über das Nutzerverhalten
                      sammeln. Wer das Speichern von Cookies für das
                      Google-Ad-Programm deaktiviert hat, wird auch beim
                      Anschauen von Youtube-Videos mit keinen solchen Cookies
                      rechnen müssen. Youtube legt aber auch in anderen Cookies
                      nicht-personenbezogene Nutzungsinformationen ab. Möchten
                      Sie dies verhindern, so müssen Sie das Speichern von
                      Cookies im Browser blockieren. Weitere Informationen zum
                      Datenschutz bei „Youtube“ finden Sie in der
                      Datenschutzerklärung des Anbieters unter:{" "}
                      <Link
                        to="https://www.google.de/intl/de/policies/privacy/"
                        className="text-[#5B9BD5]"
                      >
                        https://www.google.de/intl/de/policies/privacy/
                      </Link>
                    </p>
                  </li>
                  <li className="my-4">
                    <strong>f) Newsletter</strong>
                    <p>
                      Auf Grundlage Ihrer ausdrücklich erteilten Einwilligung,
                      übersenden wir Ihnen regelmäßig unseren Newsletter bzw.
                      vergleichbare Informationen per E-Mail an Ihre angegebene
                      E-Mail-Adresse. Für den Empfang des Newsletters ist die
                      Angabe Ihrer E-Mail-Adresse ausreichend. Bei der Anmeldung
                      zum Bezug unseres Newsletters werden die von Ihnen
                      angegebenen Daten ausschließlich für diesen Zweck
                      verwendet. Abonnenten können auch über Umstände per E-Mail
                      informiert werden, die für den Dienst oder die
                      Registrierung relevant sind (Beispielsweise Änderungen des
                      Newsletterangebots oder technische Gegebenheiten).
                    </p>{" "}
                    <br />
                    <p>
                      Für eine wirksame Registrierung benötigen wir eine valide
                      E-Mail-Adresse. Um zu überprüfen, dass eine Anmeldung
                      tatsächlich durch den Inhaber einer E-Mail-Adresse
                      erfolgt, setzen wir das „Double-opt-in“-Verfahren ein.
                      Hierzu protokollieren wir die Bestellung des Newsletters,
                      den Versand einer Bestätigungsmail und den Eingang der
                      hiermit angeforderten Antwort. Weitere Daten werden nicht
                      erhoben. Die Daten werden ausschließlich für den
                      Newsletterversand verwendet und nicht an Dritte
                      weitergegeben.
                    </p>{" "}
                    <br />
                    <p>
                      Die Einwilligung zur Speicherung Ihrer persönlichen Daten
                      und ihrer Nutzung für den Newsletterversand können Sie
                      jederzeit widerrufen. In jedem Newsletter findet sich dazu
                      ein entsprechender Link. Außerdem können Sie sich
                      jederzeit auch direkt auf dieser Webseite abmelden oder
                      uns Ihren entsprechenden Wunsch über die am Ende dieser
                      Datenschutzhinweise angegebene Kontaktmöglichkeit
                      mitteilen.
                    </p>
                  </li>
                </ol>
              </span>
            </li>
            <li className="my-2 text-[#5B9BD5]">
              {/*5*/}
              <span className="font-medium">
                Ihre Rechte als von der Datenverarbeitung betroffene Person
              </span>
              <p className="m-2 text-white">
                Verarbeitet unsere Firma personenbezogene Daten von Ihnen, sind
                Sie Betroffener im Sinne der DSGVO. Als Betroffener haben Sie
                gegenüber unserer Firma das Recht:
              </p>
              <ul className="list-disc list-inside m-2 ml-6 text-white">
                <li>
                  gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung
                  jederzeit gegenüber unserer Firma zu widerrufen. Dies hat zur
                  Folge, dass wir die Datenverarbeitung, die auf dieser
                  Einwilligung beruhte, für die Zukunft nicht mehr fortführen
                  dürfen;
                </li>
                <li>
                  gemäß Art. 15 DSGVO Auskunft über Ihre von unserer Firma
                  verarbeiteten personenbezogenen Daten zu verlangen.
                  Insbesondere können Sie Auskunft verlangen über
                </li>
                <li>die Verarbeitungszwecke,</li>
                <li>
                  die Kategorie der personenbezogenen Daten, die verarbeitet
                  werden
                </li>
                <li>
                  die Empfänger oder Kategorien von Empfängern, gegenüber denen
                  Ihre Daten offengelegt wurden oder werden,
                </li>
                <li>die geplante Speicherdauer,</li>
                <li>
                  das Bestehen eines Rechts auf Einschränkung der Verarbeitung
                  durch unsere Firma oder auf Widerspruch gegen eine solche
                  Verarbeitung,
                </li>
                <li>
                  das Bestehen eines Beschwerderechts bei einer
                  Aufsichtsbehörde,
                </li>
                <li>
                  die Herkunft ihrer Daten, sofern unsere Firma diese nicht bei
                  Ihnen selbst erhoben hat, sowie
                </li>
                <li>
                  das Bestehen einer automatisierten Entscheidungsfindung
                  einschließlich Profiling und ggf. aussagekräftige
                  Informationen zu deren Einzelheiten.
                </li>
                <li>
                  gemäß Art. 16 DSGVO unverzüglich die Berichtigung bzw.
                  Vervollständigung Ihrer bei unserer Firma gespeicherten
                  personenbezogenen Daten zu verlangen, sofern und soweit diese
                  unrichtig bzw. unvollständig sind;
                </li>
                <li>
                  gemäß Art. 17 DSGVO die Löschung Ihrer bei unserer Firma
                  gespeicherten personenbezogenen Daten zu verlangen, soweit
                  nicht die Verarbeitung zur Ausübung des Rechts auf freie
                  Meinungsäußerung und Information, zur Erfüllung einer
                  rechtlichen Verpflichtung, aus Gründen des öffentlichen
                  Interesses oder zur Geltendmachung, Ausübung oder Verteidigung
                  von Rechtsansprüchen erforderlich ist;
                </li>
                <li>
                  gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer
                  personenbezogenen Daten zu verlangen,
                </li>
                <li>soweit Sie die Richtigkeit der Daten bestreiten;</li>
                <li>
                  die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung
                  ablehnen;
                </li>
                <li>
                  unsere Firma die Daten für die vorstehend beschriebenen Zwecke
                  der Verarbeitung nicht mehr benötigt, Sie jedoch diese Daten
                  zur Geltendmachung, Ausübung oder Verteidigung von
                  Rechtsansprüchen benötigen; oder:
                </li>
                <li>
                  Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung
                  eingelegt haben.
                </li>
                <li>
                  gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns
                  bereitgestellt haben, in einem strukturierten, gängigen und
                  maschinenlesebaren Format zu erhalten oder die Übermittlung an
                  einen anderen Verantwortlichen als unsere Firma zu verlangen;
                  und:
                </li>
                <li>
                  gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu
                  beschweren. In der Regel können Sie sich hierfür an die
                  Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder
                  Arbeitsplatzes oder unseres Firmasitzes wenden.
                </li>
              </ul>
            </li>
            <li className="my-2 text-[#5B9BD5]">
              {/*6*/}
              <span className="font-medium"> Widerspruchsrecht </span>
              <p className="m-2 text-white">
                Sofern Ihre personenbezogenen Daten auf der Grundlage von
                berechtigten Interessen unserer Firma gemäß Art. 6 Abs. 1 Satz 1
                lit. f) DSGVO verarbeitet werden, haben Sie das Recht, gemäß
                Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer
                personenbezogenen Daten einzulegen, soweit dafür Gründe
                vorliegen, die sich aus Ihrer besonderen Situation ergeben.
              </p>
              <p className="m-2 text-white">
                Möchten Sie von Ihrem Widerspruchsrecht Gebrauch machen, genügt
                eine E-Mail an unsere Firma, und zwar an:
                privacy@ptscientists.com
              </p>
            </li>
            <li className="my-2 text-[#5B9BD5]">
              {/*7*/}
              <span className="font-medium"> Datensicherheit </span>
              <p className="m-2 text-white">
                Wir verwenden innerhalb des Website-Besuchs das verbreitete
                SSL-Verfahren (Secure Socket Layer) in Verbindung mit der
                jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser
                unterstützt wird. In der Regel handelt es sich dabei um eine 256
                Bit-Verschlüsselung. Falls Ihr Browser keine 256
                Bit-Verschlüsselung unterstützt, greifen wir stattdessen auf
                128-Bit-v3-Technologie zurück. Ob eine einzelne Seite unseres
                Internetauftrittes verschlüsselt übertragen wird, erkennen Sie
                an der geschlossenen Darstellung des Schüssel- beziehungsweise
                Schloss-Symbols in der unteren Statusleiste Ihres Browsers.
              </p>
              <p className="m-2 text-white">
                Wir bedienen uns im Übrigen geeigneter technischer und
                organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen
                zufällige oder vorsätzliche Manipulationen, teilweisen oder
                vollständigen Verlust, Zerstörung oder gegen den unbefugten
                Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden
                entsprechend der technologischen Entwicklung fortlaufend
                verbessert.
              </p>
            </li>
            <li className="my-2 text-[#5B9BD5]">
              {/*8*/}
              <span className="font-medium">
                Aktualität und Änderung dieser Datenschutzerklärung
              </span>
              <p className="m-2 text-white">
                Diese Datenschutzerklärung ist aktuell gültig und hat den Stand
                Mai 2018.
              </p>
              <p className="m-2 text-white">
                Durch die Weiterentwicklung unserer Website oder aufgrund
                geänderter gesetzlicher beziehungsweise behördlicher Vorgaben
                kann es notwendig werden, diese Datenschutzhinweise zu ändern.
                Die jeweils aktuellen Datenschutzhinweise unserer Firma können
                Sie jederzeit auf unserer Website unter der folgenden
                Internet-Adresse abrufen und ausdrucken:
              </p>
              <p className="m-2 text-[#5B9BD5]">
                https://ptscientists.com/privacy-de
              </p>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Privacy;