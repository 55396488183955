import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../data/SpacePort-Logo.png';

const Navbar = () => {
  return (
    <div className='flex flex-wrap justify-between p-2 md:mx-2 relative'>
      <div className='flex items-center ml-2'>
        <Link to='/'>
            <img src={logo} alt="logo" className="w-52" /> {/* bg-[#A6A6A6] bg-opacity-60 rounded-lg */}
        </Link> 
      </div>
      <div className='flex items-center space-x-2 mr-10 text-lg bg-[#5B9BD5]/70 my-10 rounded-md'>
        <Link to='/home' className='text-white font-semibold hover:bg-[#5B9BD5] p-2 rounded-md'>Home</Link>
        <Link to='/services' className='text-white font-semibold hover:bg-[#5B9BD5] p-2 rounded-md'>Services</Link> 
        <Link to='/contact' className='text-white font-semibold hover:bg-[#5B9BD5] p-2 rounded-md'>Contact</Link>
        {/*<Link to='/more' className='text-white hover:bg-[#5B9BD5] p-2 rounded-md'>More</Link>*/}
      </div>
    </div>
  )
}

export default Navbar;