import React from 'react';
import { ContactForm } from '../components';

const Contact = () => {
  return (
    <div className="max-h-full w-full text-white flex flex-col justify-start items-center">
      <div className="bg-black/75 rounded-md text-justify p-10 laptop:w-[65vw] tablet:w-[80vw]">
        <h1 className="text-center text-[#5B9BD5] text-4xl font-bold mb-10">
          Contact <span className="text-white"> #TheMoon</span>
        </h1>

        <div className="my-6">
          <ContactForm />
        </div>
      </div>
    </div>

  )
}

export default Contact