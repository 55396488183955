import React from 'react';
import { Link } from 'react-router-dom';
//import { Privacy, Legal } from '../pages';
//import logo2 from '../data/Patch-Logo.png';
import logo2 from '../data/SpacePort-Logo.png';
import mva from '../data/MVA.png';

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <footer className='text-white px-4 flex flex-wrap justify-between items-center h-full w-full pb-6'>
      {/* Logo Section */}
      <div className=''>
        <img src={logo2} alt="Logo" className="w-52 ml-4" />
      </div>

      {/* Navigation Links */}
      <div className='text-lg flex justify-center items-center'>
        <Link to="/privacy" className="m-1 p-2 font-bold text-white hover:bg-[#5B9BD5] hover:rounded-md" onClick={scrollToTop}>Privacy</Link>
        <Link to="/legal-notice" className="m-1 p-2 font-bold text-white hover:bg-[#5B9BD5] hover:rounded-md" onClick={scrollToTop}>Legal Notice</Link>
      </div>

      {/* Contact Information */}
      <div className="flex">
        {/* Partners Section */}
        <div className="text-sm mx-12 justify-center items-center">
          <p className='font-bold text-[#5B9BD5]'>Partners:</p>
          <img src={mva} alt="MVA Logo" className="w-36 mt-2" />
        </div>

        <div className="text-right text-sm mr-4">
          <div className='text-justify'>
            <p className='font-bold text-[#5B9BD5]'>Address:</p>
            <p>Planetary Transportation Systems GmbH</p>
            <p>Flughafenstraße 1</p>
            <p>18299 Laage</p>
            <p className='font-bold text-[#5B9BD5]'>E-Mail:</p>
            <p>info@spaceport-rostock.de</p>
            <p className='font-bold text-[#5B9BD5]'>Phone:</p>
            <p>+ 49 30 54 90 65 280</p>
          </div>
        </div>
        
        
      </div>
        

    </footer>
  )
}

export default Footer;