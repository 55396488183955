import React, {useState} from 'react';
import test_1 from '../data/Test_1.jpg';
import test_3 from '../data/Test_3.jpg';
import themoon_4 from '../data/TheMoon_4.jpg';
import themoon_5 from '../data/TheMoon_5.jpg';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

const Carousel = () => {
  const images = [test_1, test_3, themoon_4, themoon_5];
  const [currentIndex, setCurrentIndex] = useState(0);
  const goToNext = () => {
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  const goToPrevious = () => {
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="relative flex items-center h-full">
      <button onClick={goToPrevious} className="absolute left-0 z-10">
        <ChevronLeftIcon className="h-10 w-10 text-white bg-opacity-50 bg-[#5B9BD5] hover:bg-opacity-90 p-1 rounded-md" />
      </button>
      <div className="w-full h-full flex justify-center items-center">
        <div className="w-full h-full overflow-hidden rounded-md ">
          <img src={images[currentIndex]} alt="Space Carousel" className="object-cover w-full h-auto min-h-full max-h-full" />
        </div>
      </div>
      <button onClick={goToNext} className="absolute right-0 z-10">
        <ChevronRightIcon className="h-10 w-10 text-white bg-opacity-50 bg-[#5B9BD5] hover:bg-opacity-90 p-1 rounded-md" />
      </button>
    </div>
  );
}

export default Carousel;