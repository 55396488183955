import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Home, Services, Contact, Legal, Privacy } from './pages';
import { Navbar, Footer } from './components';
import picbackground from '../src/data/moon_earth.jpg';

function App() {
  return (
    <div className='overflow-none' style={{
      backgroundImage: `url(${picbackground})`,
      backgroundSize: 'cover', 
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
    
     }}>
      <div className='overflow-y-auto h-full w-full flex flex-col'>
        <Router>
          
          <div className='top-0 left-0 right-0 h-fit z-[10] mb-12'>
            <Navbar />
          </div>
          <div className='flex-grow pb-4'>
            <Routes>
              <Route exact path='/' element= { <Home /> } />
              <Route exact path='/home' element= { <Home /> } />
              <Route exact path='/services' element= { <Services /> } />  
              <Route exact path='/contact' element= { <Contact /> } />
              <Route exact path='/privacy' element= { <Privacy /> } />
              <Route exact path='/legal-notice' element= { < Legal /> } />
            </Routes>
          </div>
          <div className='bg-opacity-50 bg-gradient-to-b from-transparent to-[#191919] inset-x-0 bottom-0 h-fit rounded-md z-[10] mt-12'>
            <Footer />
          </div>
        </Router>
      </div>
    </div>
  );
}

export default App;
