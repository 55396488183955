import React from "react";
import { Carousel } from "../components";
import video from "../data/SpacePortRostock.mp4";
import impre from "../data/info.png";

const Home = () => {
  return (
    <div className="bg-transparent max-h-full w-full flex flex-col justify-start items-center">
      <div className="bg-black/75 mb-6 rounded-md p-4 laptop:w-[65vw] tablet:w-[80vw]">
        <div className="text-center">
          <h1 className="text-white text-4xl font-bold">
            SpacePort Rostock
            <span className="text-[#5B9BD5]"> #TheMoon</span>
          </h1>

          <h2 className="text-[#5B9BD5] text-2xl mb-2 rounded-md px-2 ">
            Europe's first fully privately financed lunar test facility
          </h2>
        </div>

        <div className="mt-5 mx-8">
          <p className="text-white text-md laptop:text-lg">
            <span className="text-[#5B9BD5] font-bold">#TheMoon</span> stretches
            over around 90m² and 60 tons of regolith simulant and is ideal
            suited for lunar surface experiments or rover tests. The large
            rectangular size of the testbed allows vehicles to be tested under
            the harsh conditions of regolith and allows terrain modelling as
            well as gravity assist thanks to a crane environment for up to 500kg
            vehicles. In addition,{" "}
            <span className="text-[#5B9BD5] font-bold">#TheMoon</span> can be
            completely darkened and illuminated with professional lights to
            simulate dedicated shadow conditions, e.g., at the lunar pole
            regions.
          </p>
        </div>
        <div className="mt-3 mx-8">
          <p className="text-white text-md laptop:text-lg">
            <span className="text-[#5B9BD5] font-bold">#TheMoon</span> is
            conveniently located directly in the airport terminal of
            Rostock-Laage. Open science is meant literately here, by allowing
            the passengers and visitors of the airport to take a look at the
            experiments. At the same time, equipment and experiments can be
            shipped by plane, truck or ship, as the airport is located at the
            motorway to the harbour of Rostock.
          </p>
        </div>
        <div className="mt-3 mx-8">
          <p className="text-white text-md laptop:text-lg">
            <span className="text-[#5B9BD5] font-bold">#TheMoon</span> is open
            to anybody: research institutes are as welcome as companies or even
            private parties. No science justification or paperwork is needed.
            Just contact us to book your slot!
          </p>
        </div>

        <div className="text-center mt-6">
          <h1 className="text-white text-3xl font-bold">
            Impressions
            <span className="text-[#5B9BD5]"> #TheMoon</span>
          </h1>
        </div>
        <div className="mt-5 text-justify laptop:mx-24">
          <h2 className="text-[#5B9BD5] text-2xl font-medium mb-2 rounded-md px-2 text-center">
            Regolith Testbed Features
          </h2>
          <div>
            <ul className="list-disc list-inside m-2 space-y-4 ml-6 text-white laptop:text-lg">
              <li>
                Enjoy a comprehensive simulation environment with our{" "}
                <strong>17m x 5m x 40cm regolith simulant testbed</strong>,
                designed specifically for intricate driving and ground
                experiments. This facility enables realistic modeling for a
                variety of lunar surface conditions.
              </li>
              <li>
                <strong>Lunar Lighting Simulations:</strong> Our testbed is
                equipped with advanced technologies to simulate lunar lighting
                conditions, featuring capabilities for both darkening scenarios
                and high-intensity spotlight simulations.
              </li>
              <li>
                <strong>Mobile Gantry Support:</strong> Features a robust 500kg
                mobile gantry system for gravity assist, allowing for precise
                and controlled manipulation of payloads in simulated low-gravity
                environments.
              </li>
              <li>
                <strong>Enhanced Surveillance and Connectivity:</strong> The
                facility is outfitted with WLAN and integrated camera
                surveillance, supporting both visual and infrared modalities,
                complete with recording capabilities.
              </li>
            </ul>
          </div>
          <h2 className="text-[#5B9BD5] text-2xl font-medium mb-2 rounded-md px-2 text-center">
            Additional Facilities
          </h2>
          <div>
            <ul className=" m-2 ml-6 text-white laptop:text-lg">
              <li>
                Complementary to our regolith testbed, the facility includes an
                outdoor area layered with sand and concrete, equipped with{" "}
                <strong>5 tons of regolith-simulant</strong> for diverse
                material tests.
              </li>
            </ul>
          </div>
          <h2 className="text-[#5B9BD5] text-2xl font-medium mb-2 rounded-md px-2 text-center">
            Flexible Usage and Access
          </h2>
          <div>
            <ul className="list-disc list-inside space-y-4 m-2 ml-6 text-white laptop:text-lg">
              <li>
                Our doors are open to research institutes, startups, and
                industry customers, promoting innovation across various sectors
                without the need for extensive paperwork or scientific
                justification.
              </li>
              <li>
                <strong>Cost-Effective Rates:</strong> Access our
                state-of-the-art testbed along with office spaces and modest
                laboratory facilities at competitive daily rates ranging from
                1,500 to 2,000 EUR for periods ranging from 1 to 30 days.
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-5 flex justify-center items-center">
          <img
            src={impre}
            alt="Impressions"
            className="max-h-[30vh]  h-auto m-5"
          />
        </div>
        <div className="mt-5 mx-10">
          <Carousel />
        </div>
        <div className="mt-10 mx-10">
          <video src={video} controls className="w-full h-full object-cover">
            Your browser does not support the video.
          </video>
        </div>
      </div>
    </div>
  );
};

export default Home;
