import React from 'react';
import logo_pts from '../data/PTS_Spacelab.svg';

const Legal = () => {
  return (
    <div className="max-h-full w-full text-white flex flex-col justify-start items-center">
      <div className="bg-black/75 my-6 rounded-md text-justify p-4 w-[80vw] xl:w-[70vw] 2xl:w-[60vw] flex flex-col">
        <h1 className="text-center text-[#5B9BD5] text-4xl font-bold mb-6">
          Legal Notice
        </h1>
        <h2 className='text-2xl font-medium text-[#5B9BD5]'>Contact Data:</h2> <br />
        <p>Planetary Transportation Systems GmbH</p> <br />
        <p><span className='font-semibold'>CEO:</span> Sebastian Mader</p> <br />
        <p>Albert-Einstein-Ring 22</p>
        <p>14532 Kleinmachnow</p> <br />
        <p>Germany</p> <br />

        <h2 className='text-2xl font-medium text-[#5B9BD5]'>Handelsregister:</h2> <br />
        <p>Berlin, HRB 209645B</p> <br />

        <h2 className='text-2xl font-medium text-[#5B9BD5]'>USt-IdNr.:</h2> <br />
        <p>DE326179650</p> <br />

        <h2 className='text-2xl font-medium text-[#5B9BD5]'>Email:</h2> <br />
        <p>info@pts.space</p> <br />

        <h2 className='text-2xl font-medium text-[#5B9BD5]'>Phone:</h2> <br />
        <p>+ 49 30 54 90 65 280</p> <br />

        <h2 className='text-2xl font-medium text-[#5B9BD5]'>Fax:</h2> <br />
        <p>+49 30 92036483</p> <br />

          <img src={logo_pts} alt="PTS Logo" className="m-10 max-w-52 h-auto " />
        
        
      </div>
    </div>
  )
}

export default Legal;